function rangeUpdate($input) {
    const val = parseInt($input.val(), 10);
    const min = parseInt($input.attr("min"), 10);
    const max = parseInt($input.attr("max"), 10);
    const pos = ((val - min) / (max - min)) * 100;
    const thumbWidth = 22;
    const offset = Math.round(thumbWidth * pos / 100) - (thumbWidth / 2);

    $input
        .next(".input__range-current")
        .css({"left": "calc(" + pos + "%" + " - " + offset + "px)"})
        .find("span")
        .html(val);
}

$("input[type='range']").each(function() {
    const $input = $(this);
    const $parent = $(this).parents(".input--range");
    const step = parseInt($input.attr("step"), 10);
    
    rangeUpdate($input);

    $parent
        .find(".input__range-minus")
        .on("click", function() {
            $input.val(parseInt($input.val(), 10) - step);
            rangeUpdate($input);
        });

    $parent
        .find(".input__range-plus")
        .on("click", function() {
            $input.val(parseInt($input.val(), 10) + step);
            rangeUpdate($input);
        });
});

$("input[type='range']").on("input", function(e) {
    const $input = $(this);
    rangeUpdate($input);
});

$("input, textarea").on("focus", function() {
    if($(this).attr("placeholder").length) {
        $(this).data("placeholder", $(this).attr("placeholder"));
    }

    $(this)
        .attr("placeholder", "")
        .closest(".input")
        .addClass("input--active");
});

$("input, textarea").on("blur", function() {
    console.log($(this).data("placeholder"));
    if(!$(this).val().length) {
        $(this)
            .attr("placeholder", $(this).data("placeholder"))
            .closest(".input")
            .removeClass("input--active");
    }
});

const maxTabs = $(".cases__tabs-item").length - 1;
let currentTab = 0;

$(".cases__tabs-item").on("click", function() {
    const $tab = $(this);
    const tabIndex = $tab.index();

    $tab
        .addClass("cases__tabs-item--active")
        .siblings()
        .removeClass("cases__tabs-item--active");

    $(".cases__slider-wrap")
        .eq(tabIndex)
        .show(0, function() {
            $(".cases__slider").slick('setPosition'); 
        })
        .addClass("cases__slider-wrap--active")
        .siblings()
        .hide(0)
        .removeClass("cases__slider-wrap--active");
});

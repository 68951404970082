class VideoReviewsRenderer {
  constructor($renderTo, template, showAtOnce) {
    this.showAtOnce = showAtOnce || 3;
    this.$renderTo = $renderTo;
    this.template = template || function(videoEmbedID, title, hidden, key) {
      return `
        <div class="video-reviews__list-item ${hidden ? 'video-reviews__list-item--hidden' : ''}">
          <iframe 
            src="https://www.youtube.com/embed/${videoEmbedID}" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
          <div class="video-reviews__list-item-title">${title}</div>
        </div>
      `;
    }
    
    this.isFirstRender = true;
    this.$lastItems = [];
  }

  render(items) { 
    let { template, showAtOnce, $renderTo, isFirstRender } = this;

    this.$lastItems.forEach(function(item, index) {
      $(item).show(300).removeClass('video-reviews__list-item--hidden');
    });

    this.$lastItems = [];

    const layout = items.map(function(item, index) {
      const { embedID, title } = item;

      return $(template(embedID, title, (isFirstRender && index >= showAtOnce) || !isFirstRender));
    });

    layout.slice(-showAtOnce).forEach((item, index) => {
      this.$lastItems.push(item);
    });

    this.isFirstRender = false;
    $renderTo.append(layout);
  }
}

export { VideoReviewsRenderer };

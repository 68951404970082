$(window).on("scroll", function() {
    if($(window).scrollTop() >= $(".header").innerHeight() - $(".navbar__list-wrap").innerHeight()) {
        $(".navbar")
            .addClass("navbar--fixed")
            .css({top: -$(".navbar__heading-wrap").innerHeight() - 1})
    } else {
        $(".navbar")
            .removeClass("navbar--fixed")
            .css({top: 0})
    }
});

var spy = new Gumshoe('#navbar a', {
    navClass: 'navbar__list-item--active',
    offset: $(".navbar__list-wrap").innerHeight(),
});

ymaps.ready(init);
function init(){
    let myMap = new ymaps.Map("map", {
        center: [48.707127, 44.474988],
        zoom: 16
    });
    
    myMap.behaviors.disable('scrollZoom')

    let placemark = new ymaps.Placemark([48.707127, 44.474988]);
    myMap.geoObjects.add(placemark);
}


const maxSteps = $(".calculator__tabs-item").length - 1;
let currentStep = 0;
let heat = [
    /*{
        id: 1,
        node: node,
        dropdownNode: dropdownNode,
        child: [
            {
                id: 2,
                node: node,
                dropdownNode: dropdownNode,
                child: {
                    id: 3,
                    node: node,
                }
            },
            {
                id: 3,
                node: node,
                dropdownNode: dropdownNode,
                child: {
                    id: 3,
                    node: node,
                }
            }
        ]
    }*/
];

let currentHeat = [];

$("[data-heat-open]").on("click", function() {
    const heatid = $(this).data("heat-open");
    console.log(heatid);

    $(`.heat-system__list[data-heat='${heatid}']`)
        .fadeIn(300)
        .addClass("heat-system__list--active")
        .siblings(".heat-system__list")
        .fadeOut(0)
        .removeClass("heat-system__list--active");

    currentHeat = heatid;
});


function nextStep() {
    if(currentStep >= maxSteps)
        return;

    $(".calculator__steps-item")
        .eq(currentStep)
        .removeClass("calculator__steps-item--current")    
        .addClass("calculator__steps-item--passed")
        .next()
        .addClass("calculator__steps-item--current");

    $(".calculator__tabs-item")
        .eq(currentStep)
        .next()
        .slideDown(400)
        .addClass("calculator__tabs-item--active")
        .siblings()
        .slideUp(400)
        .removeClass("calculator__tabs-item--active");

    if($(".calculator__prev").is(":hidden"))
        $(".calculator__prev").show();

    if($(".calculator__next").is(":hidden"))
        $(".calculator__next").show();

    currentStep = currentStep + 1;

    $(".calculator__progress-current")
        .css({width: `${currentStep / (maxSteps + 1) * 100}%`});

    if(currentStep == 3) 
        $(".calculator__next").hide();

    if(currentStep == maxSteps) 
        $(".calculator__next")
            .html("Отправить")
            .attr("type", "submit");
}

function prevStep() {
    if(currentStep <= 0)
        return;

    if(currentStep != 3 || currentHeat == 0) {
        $(".calculator__steps-item")
            .eq(currentStep)
            .removeClass("calculator__steps-item--current")
            .removeClass("calculator__steps-item--passed")      
            .prev()
            .removeClass("calculator__steps-item--passed")
            .addClass("calculator__steps-item--current");

        $(".calculator__tabs-item")
            .eq(currentStep)
            .slideUp(400)
            .removeClass("calculator__tabs-item--active")
            .prev()
            .slideDown(400)
            .addClass("calculator__tabs-item--active");
        
        currentStep = currentStep - 1;

        
        $(".calculator__progress-current")
            .css({width: `${currentStep / (maxSteps + 1) * 100}%`});

        if($(".calculator__next").is(":hidden"))
            $(".calculator__next").show();

        if(currentStep == 3)
            $(".calculator__next").hide();

        if(currentStep == 0)
            $(".calculator__next").hide();
    } else {
        const heatid = $(".heat-system__list--active").data("heat");

        $(`.heat-system__list-item[data-heat-open='${heatid}']`)
            .closest(".heat-system__list")
            .fadeIn(300)
            .addClass("heat-system__list--active")
            .siblings(".heat-system__list")
            .fadeOut(0)
            .removeClass("heat-system__list--active");

        currentHeat = $(`.heat-system__list-item[data-heat-open='${heatid}']`).closest(".heat-system__list").data("heat");
    }

    if(currentStep <= 0)
        $(".calculator__prev").hide(); 

    if(currentStep != maxSteps)
        $(".calculator__next")
            .html("Следующий шаг")
            .attr("type", "");
}

$(".calculator__next").on("click", function(e) {
    nextStep();

    if(currentStep != maxSteps)
        e.preventDefault();
});

$(".calculator__prev").on("click", function(e) {
    prevStep();

    e.preventDefault();
});

$(".calculator__objects .objects__list-item").on("click", function() {
    nextStep();
});

$(".heat-system__list-item--last").on("click", function() {
    const value = $(this).data("value");
    $("input[name='heat-system']").val(value);
    $(".heat-system__list-item--last").each(function() {
        $(this).removeClass("heat-system__list-item--selected");
    });
    $(this).addClass("heat-system__list-item--selected")
    nextStep();
});

export {currentStep, maxSteps};

class PopupManager {
    constructor() {
        this.current = null;
    }
    
    show(id, autohide = false) {
        if(this.current !== null) {
            this.hide(this.current);
        }

        this.current = id;
        const popup = document.querySelector(".pop-up[data-pop-up='" + id + "']");

        if(document.body.clientHeight > window.innerHeight) {
            console.log(document.body.clientHeight, window.innerHeight);
            document.body.style.overflowY = "scroll";
        }
        
        popup.style.display = "flex";
        document.body.classList.add("body-fixed");
    
        if(autohide) {
            setTimeout(function() {
                // this.hide(id)
            }, 4000);
        }
    }

    hide(id = this.current) {
        if(id === null) return;
        this.current = null;
        document.body.classList.remove("body-fixed");
        document.body.style.overflowY = "auto";
        document.querySelector(".pop-up[data-pop-up='" + id + "']").style.display = "none";
    }
}

$("body").on("click", ".pop-up__opener", function() {
	var popup = $(this).data("pop-up");

	if(popup == "dynamic") {
		$opener = $(this);
		$popup = $(".pop-up[data-pop-up='" + popup + "']");

		$popup.find(".pop-up__title").html($opener.data("title"));
		$popup.find(".pop-up__desc").html($opener.data("desc"));
		$popup.find("button").html($opener.data("button"));
		$popup.find("input[name='dyntitle']").val($opener.data("title"));
	}

	Popup.show($(this).data("pop-up"));
});

$(".pop-up__close, .pop-up__bg").on("click", function(e) {
	Popup.hide();
});


const Popup = new PopupManager();

export {Popup};


/*var scroll = 0,
	saveScroll,
	currentPopUp = -1;

function showPopUp(popUpId) {
	if(currentPopUp != -1) {
		hidePopUp(currentPopUp);
	}
	currentPopUp = popUpId;
	saveScroll = scroll;

	var $popUp = $(".pop-up[data-pop-up='" + popUpId + "']");

	$("body").addClass("body-fixed");
	$popUp.fadeIn(300).css("display", "flex");

	if(popUpId == "success") {
		setTimeout(function() {
			hidePopUp(popUpId)
		}, 4000);
	}
}

function hidePopUp(popUpId) {
	if(currentPopUp === -1) return;
	currentPopUp = -1;
	$("body").removeClass("body-fixed");
	
	$(".pop-up[data-pop-up='" + popUpId + "']").fadeOut(300);
}

$(window).scroll(function () {
    scroll = $(window).scrollTop();
});

$(function() {
	$("body").on("click", ".pop-up__opener", function() {
		var popup = $(this).data("pop-up");

		if(popup == "dynamic") {
			$opener = $(this);
			$popup = $(".pop-up[data-pop-up='" + popup + "']");

			$popup.find(".pop-up__title").html($opener.data("title"));
			$popup.find(".pop-up__desc").html($opener.data("desc"));
			$popup.find("button").html($opener.data("button"));
			$popup.find("input[name='dyntitle']").val($opener.data("title"));
		}

		showPopUp($(this).data("pop-up"));
	});

	$(".pop-up__close, .pop-up__bg").on("click", function(e) {
		hidePopUp(currentPopUp);
	});
	
});
*/

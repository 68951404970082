let faqShowed = false;

$(".faq__list-item-readmore, .faq__list-item-status").on("click", function() {
    let $parent = $(this)
        .parents(".faq__list-item")

    if($parent.hasClass("faq__list-item--active")) {
        $parent
            .find(".faq__list-item-answer")
            .slideUp(150);
    } else {
        $parent
            .find(".faq__list-item-answer")
            .slideDown(500);
    }

    $(this)
        .parents(".faq__list-item")
        .toggleClass("faq__list-item--active");
});

$(".faq__show").on("click", function() {
    $(".faq").toggleClass("faq--showed");

    if(faqShowed) {
        $(this).html("Читать еще вопросы");
    } else {
        $(this).html("Скрыть вопросы");
    }

    faqShowed = !faqShowed;
});

/* THIS SCHWARZNIGGER'S CODE MUST BE REFACTORED, NO WAY */
window.$ = require("jquery");
window.jQuery = window.$; 
require("slick-carousel");

window.Gumshoe = require("gumshoejs");
window.SmoothScroll = require("smooth-scroll");
window.Popup = require("./popup");
require("@fancyapps/fancybox");

$(function() {
    require("./map");
    require("./input");
    window.calculator = require("./calculator");
    require("./navbar");
    require("./sliders");
    require("./cases");
    require("./smothscroll");
    require("./validation");
    require("./mask");
    require("./faq");
    require("./reviews");
    require("./ajax-send");
    require("./scroll-top");
});

/* IDK, NEW FUNCTIONAL I DROP HERE */
import { VideoReviews } from "./VideoReviews";

const videoReviews = new VideoReviews({
    wrapNode: '.video-reviews',
    showItemsAtOnce: 3,
}, [{
    title: 'Электрическая пленочная система',
    link: 'https://www.youtube.com/embed/VQP5ziOgO00'
}, {
    title: 'Инфракрасная система отопления потолочного типа в частном доме',
    link: 'https://www.youtube.com/embed/TZBYAX_ixSc'
}, {
    title: 'Пленочной система отопления',
    link: 'https://www.youtube.com/embed/iKSjx1kGGMQ'
}]);

$(".cases__slider").each(function(i, e) {
    $(e).slick({
        slidesToShow: 1,
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 7000,
        speed: 500,
        swipeToSlide: true,
        prevArrow: $(e).parents(".cases__slider-wrap").find(".cases__slider-arrow--left"),
        nextArrow: $(e).parents(".cases__slider-wrap").find(".cases__slider-arrow--right")
    });
});

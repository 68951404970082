let sending = false;

$(".form").on("submit", function(e) {
	let $form = $(this);
	let formtype = $form.find("input[name='formtype']").attr("value");
	if (sending || !$form.valid()) return;
	let buttonValue = $form.find("button[type='submit']").html();

	$.ajax({
		method: 'POST',
		url: '/forms/send.php',
		data: $form.serialize(),
		beforeSend: function () {
			sending = true;
			$form.find("button[type='submit']").html("Отправка...");
		},
		success: function () {
			$form[0].reset();
			Popup.Popup.show("success", true);
			ym(56718988, 'reachGoal', formtype);
		},
		error: function () {
			$form[0].reset();
			Popup.Popup.show("fail");
		},
		complete: function (response) {
			console.log(response);
			sending = false;
			$form.find("button[type='submit']").html(buttonValue);

			if(formtype == "calculator") {
				calculator.currentStep = 0;

				$(".calculator__steps-item")
					.eq(calculator.currentStep)
					.addClass("calculator__steps-item--current")
					.removeClass("calculator__steps-item--passed")      
					.siblings()
					.removeClass("calculator__steps-item--passed")
					.removeClass("calculator__steps-item--current");
		
				$(".calculator__tabs-item")
					.eq(calculator.currentStep)
					.slideDown(400)
					.addClass("calculator__tabs-item--active")
					.siblings()
					.slideUp(400)
					.removeClass("calculator__tabs-item--active");
				
				$(".calculator__progress-current")
					.css({width: `${calculator.currentStep / (calculator.maxSteps + 1) * 100}%`});
		
				$(".calculator__next").hide();
				$(".calculator__prev").hide(); 

				$(".calculator__next")
					.html("Следующий шаг")
					.attr("type", "");
			}
		}
	});

	e.preventDefault();
});

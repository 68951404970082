import { VideoReviewsRenderer } from './Renderer';

class VideoReviews { 
  constructor(options, videos) {
    if(options.wrapNode === undefined)
      throw new Error('The assigment of wrapNode is required.')

    if(videos === undefined || !videos.length) {
      $(options.wrapNode).hide();
      return false;
    }

    this.$wrapNode = $(options.wrapNode);
    this.showItemsAtOnce = options.showItemsAtOnce || 3;
    this.alreadyShown = 0;

    this.renderer = new VideoReviewsRenderer(this.$wrapNode.find('.video-reviews__list'), options.itemTemplate, this.showItemsAtOnce);

    this.videos = [];
    videos.forEach((video, index) => {
      this.videos.push({
        embedID: this.getEmbedIDFromLink(video.link),
        title: video.title
      });
    });

    this.initialize();
  }

  initialize() {
    const { $wrapNode } = this;

    this.nextVideos();

    if(this.videos.length <= this.showItemsAtOnce)
      return $wrapNode.find('.show-more').hide();

    $wrapNode
      .find('button.show-more')
      .on('click', this.showMoreClickHandler.bind(this));
  }

  showMoreClickHandler(e) {
    this.nextVideos();

    if((this.alreadyShown - this.showItemsAtOnce) >= this.videos.length) {
      $(e.target).hide();
    }

    e.preventDefault();
  }

  nextVideos() {
    const { alreadyShown, showItemsAtOnce, renderer } = this;

    const items = this.getItemsForRender();
    renderer.render(items);

    this.alreadyShown += showItemsAtOnce * (alreadyShown === 0 ? 2 : 1);
  }

  getItemsForRender() {
    const { videos, alreadyShown, showItemsAtOnce } = this;

    const start = alreadyShown;
    const end = alreadyShown + showItemsAtOnce * (alreadyShown === 0 ? 2 : 1);

    return videos.slice(start, end);
  }
  
  getEmbedIDFromLink(link) {
    const regexp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const embedID = link.match(regexp)
    
    if(!embedID[7])
      throw new Error(`Can't extract video ID from link: "${link}"`);

    return embedID[7];
  }
}

export { VideoReviews };

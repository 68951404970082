$(".reviews__list-item-readmore, .reviews__list-item-status").on("click", function() {
    let $parent = $(this)
        .parents(".reviews__list-item")

    if($parent.hasClass("reviews__list-item--active")) {
        $parent
            .find(".reviews__list-item-answer")
            .slideUp(150);
    } else {
        $parent
            .find(".reviews__list-item-answer")
            .slideDown(500);
    }

    $(this)
        .parents(".reviews__list-item")
        .toggleClass("reviews__list-item--active");
});
